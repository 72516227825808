// JS from old site
!function t(e, n, a) {
    function o(r, s)
    {
        if ( !n[r] )
        {
            if ( !e[r] )
            {
                var u = "function" == typeof require && require;
                if ( !s && u )
                    return u(r, !0);
                if ( i )
                    return i(r, !0);
                throw new Error("Cannot find module '" + r + "'")
            }
            var c = n[r] = {
                exports: {}
            };
            e[r][0].call(c.exports, function(t) {
                var n = e[r][1][t];
                return o(n ? n : t)
            }, c, c.exports, t, e, n, a)
        }
        return n[r].exports
    }

    for ( var i = "function" == typeof require && require, r = 0; r < a.length; r++ )
    {
        o(a[r]);
    }
    return o
}({
    1: [function() {
        "use strict";
        var t   = "#ed1c24"
            , e = "#ffe5e7";
        if ( $(".key-numbers li .graph").each(function() {
            var n   = $(this).data("percentage")
                , a = parseFloat(n.replace(",", "."));
            var o   = $("<div>").attr("class", "container")
                , i = $("<div>").attr("class", "label").text(n)
                , r = 110
                , s = $("<canvas>").attr({
                width: r,
                height: r
            });
            new Chart(s[0].getContext("2d")).Doughnut([{
                value: a,
                color: t
            }, {
                value: 100 - a,
                color: e
            }], {
                animationEasing: "easeOutQuart",
                percentageInnerCutout: 75
            }),
                o.append(i).append(s),
                $(this).append(o)
        }),
            $(".organization-content").length )
        {
            new Vue({
                el: ".organization-content",
                data: function() {
                    return {
                        organizations: [],
                        tags: [],
                        searchText: '',
                        siteSearchTimeout: null
                    }
                },
                watch: {
                    searchText()
                    {
                        clearTimeout(this.siteSearchTimeout)
                        this.siteSearchTimeout = setTimeout(() => {
                            this.trackSearch()
                        }, 2000)
                    }
                },
                created: function() {
                    orgApiURI && $.getJSON(orgApiURI, function(t) {
                        this.organizations = t.organizations
                        this.tags = t.tags
                    }
                        .bind(this))
                },
                computed: {
                    filteredOrganizations()
                    {
                        return this.organizations.filter((item) => {
                            return item.name
                                .toLowerCase()
                                .includes(this.searchText.toLowerCase())
                        })
                    },
                    filteredTags()
                    {
                        let filtered = this.tags.filter((item) => {
                            return item.name
                                .toLowerCase()
                                .includes(this.searchText.toLowerCase())
                        })

                        filtered = filtered.sort((a, b) => a.name.localeCompare(b.name))

                        return filtered
                    }
                },
                methods: {
                    trackSearch: function() {
                        // @note: There exists a duplicate of this method in this file
                        if ( this.searchText && this.searchText !== '' && this.latestTrackedSearch !== this.searchText )
                        {
                            this.latestTrackedSearch = this.searchText
                            if ( typeof ga === 'function' )
                            {
                                ga.getAll().forEach((tracker) => {
                                    const keyword = encodeURIComponent(this.searchText)
                                    tracker.set('page', '/search?q=' + keyword);
                                    tracker.send('pageview');
                                });
                            }
                        }
                    }
                }
            })
        }
        if ( $(".fee-calculator").length )
        {
            new Vue({
                el: ".fee-calculator",
                data: function() {
                    return {
                        income: 0,
                        subsidies: 0
                    }
                },
                computed: {
                    fee: function() {
                        var t   = 0
                            , e = 2e3
                            , n = 4e4
                            , a = 1e7
                            , o = .002
                            , i = .001
                            , r = parseInt(this.income - this.subsidies)
                            , s = r - a;
                        return t += s > 0 ? a * o + s * i : r * o,
                            e > t ? t = e : t > n && (t = n),
                            Math.round(t)
                    }
                }
            })
        }
        if ( $(".tabs .tabs-nav").on("click", ".tab", function() {
            var t   = $(this).closest(".tabs")
                , e = t.find("." + $(this).data("tab"));
            t.find(".tab-page").hide(),
                e.show(),
                $(this).closest(".tabs-nav").find(".tab").removeClass("tab-selected"),
                $(this).addClass("tab-selected")
        }),
            $(".tabs .tabs-nav .tab-selected").click(),
            $(".org .information .read-more").click(function(t) {
                t.preventDefault(),
                    $(".org .information .extra-info").show(),
                    $(this).hide()
            }),
            $(".frontpage-box").length )
            var n = new Vue({
                el: ".frontpage-box",
                data: {
                    rotationInterval: null,
                    searchText: '',
                    tags: [],
                    siteSearchTimeout: null,
                    latestTrackedSearch: null
                },
                watch: {
                    searchText()
                    {
                        clearTimeout(this.siteSearchTimeout)
                        this.siteSearchTimeout = setTimeout(() => {
                            this.trackSearch()
                        }, 2000)
                    }
                },
                created: function() {
                    $.getJSON("/wp-json/innsamlingskontrollen/v1/all-organizations", function(t) {
                        this.organizations = t.organizations
                        this.tags = t.tags
                        this.enableRotation()
                    }
                        .bind(this))
                },
                computed: {
                    filteredOrganizations()
                    {
                        let filtered = this.organizations.filter((item) => {
                            return item.name
                                .toLowerCase()
                                .includes(this.searchText.toLowerCase())
                        })

                        filtered = filtered.sort((a, b) => a.name.localeCompare(b.name))

                        return filtered
                    },
                    filteredTags()
                    {
                        let filtered = this.tags.filter((item) => {
                            return item.name
                                .toLowerCase()
                                .includes(this.searchText.toLowerCase())
                        })

                        filtered = filtered.sort((a, b) => a.name.localeCompare(b.name))

                        return filtered
                    }
                },
                methods: {
                    enableRotation: function() {
                        var t   = $(".frontpage-box input")
                            , e = $(".frontpage-box .rotation");
                        t.val() || (t.attr({
                            placeholder: ""
                        }),
                            e.show(),
                            this.rotationInterval = setInterval(this.updateRotation, 3e3),
                            this.updateRotation())
                    },
                    disableRotation: function() {
                        var t   = $(".frontpage-box input")
                            , e = $(".frontpage-box .rotation");
                        e.hide(),
                            clearInterval(this.rotationInterval),
                            t.attr({
                                placeholder: "Søk etter organisasjoner"
                            }),
                            setTimeout(function() {
                                t.select()
                            }, 1)
                    },
                    updateRotation: function() {
                        var t   = n.organizations[Math.floor(Math.random() * n.organizations.length)]
                            , e = $(".frontpage-box .rotation");
                        e.animate({
                            opacity: 0
                        }, 500, function() {
                            e.text(t.name),
                                e.animate({
                                    opacity: 1
                                }, 500)
                        })
                    },
                    trackSearch: function() {
                        // @note: There exists a duplicate of this method in this file
                        if ( this.searchText && this.searchText !== '' && this.latestTrackedSearch !== this.searchText )
                        {
                            this.latestTrackedSearch = this.searchText
                            if ( typeof ga === 'function' )
                            {
                                ga.getAll().forEach((tracker) => {
                                    const keyword = encodeURIComponent(this.searchText)
                                    tracker.set('page', '/search?q=' + keyword);
                                    tracker.send('pageview');
                                });
                            }
                        }
                    }
                }
            })
    }
        , {}]
}, {}, [1]);
